import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyC5BFHeS5L8z83cVMW5jnLO7kukfh85MXQ",
  authDomain: "mailinnogcarl.firebaseapp.com",
  projectId: "mailinnogcarl",
  storageBucket: "mailinnogcarl.appspot.com",
  messagingSenderId: "32757197005",
  appId: "1:32757197005:web:6d6250339e52ba44ea6184",
  measurementId: "G-VYJK8RBHL3",
};

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
