import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';

interface MenuProps {
    open: boolean
}
const Menu = ({ open, ...props }: MenuProps) => {

    const isHidden = open ? true : false;
    const tabIndex = isHidden ? 0 : -1;

    return (
        <StyledMenu open={open} aria-hidden={!isHidden} {...props}>

            <a href="/" tabIndex={tabIndex}>
                Hjem
            </a>
            <a href="/agenda" tabIndex={tabIndex}>
                Agenda
            </a>

            <a href="/info" tabIndex={tabIndex}>
                Informasjon
            </a>

            <a href="/gaver" tabIndex={tabIndex}>
                Gaver
            </a>
            <a href="/rsvp" tabIndex={tabIndex}>
                RSVP
            </a>
        </StyledMenu>
    )
}

Menu.propTypes = {
    open: bool.isRequired,
}

export default Menu;
