import React from 'react'
import Layout from '../components/Layout/Layout';
import HeaderImage from '../components/HeaderImage';
import NavBar from '../components/NavBar/NavBar';
import styled from 'styled-components';
import gifts from '../Images/Misc/gifts.jpg';
import kitchn from "../Images/Misc/kitchn.png"
import glassmagasinet from "../Images/Misc/glassmagasinet.jpg"
import FullPage from '../components/UI/FullPage';

const Section = styled.div`
    background: ${({ theme }) => theme.grey};
    height: 100dvh;
    width: 100%;
    position: relative;
    padding: 80px 50px 50px 50px;

    @media (max-width: 768px) {

        padding: 48px;
    }
`;

const StyledGiftItem = styled.div`
    background: white;
    height: 300px;
    width: 500px;
    border-radius: 10px;
    margin: 50px;

    filter: drop-shadow(gray 1rem 1rem 10px);

    display: flex;
    flex-direction: column;

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
    }

    min-width: 260px;

    @media (max-width: 768px) {
        margin: 10px;
    }
`

const GiftItemImageKitchn = styled.img`
    width: 100%;
    height: 50%;
    object-fit: none;
    object-position: 0% 8%;

`

const GiftItemImageGlass = styled.img`
    width: 100%;
    height: 50%;
    object-fit: none;
    object-position: 40% 20%;
`

const GiftItemTitle = styled.h2`
    display: flex;
    font-size: 28px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin: 10px;
`

const GiftWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

interface GiftItemProps {
    url?: string;
    img: string;
    alt: string;
    title: string;

}

const GiftItem = ({ url, img, title, alt }: GiftItemProps) => {
    return <StyledGiftItem onClick={() => window.open(url)}>
        {title == "Kitch'n" ?
            <GiftItemImageKitchn src={img} alt={alt} /> : <GiftItemImageGlass src={img} alt={alt} />
        }

        <GiftItemTitle>{title}</GiftItemTitle>

    </StyledGiftItem>
}

const Gifts = () => {
    const section1 = <>

        <NavBar variant='light' />
        <HeaderImage src={gifts} title='Gaveliste' alt="Bilde av gaver" />
    </>
    const section2 = <>

        <Section>
            <GiftWrapper>
                <GiftItem img={kitchn} alt="Bilde av Kitch'n" title="Kitch'n" url='https://www.kitchn.no/onskeliste/169751' />
                <GiftItem img={glassmagasinet} alt="Bilde av Glasmagasinet" title="Glasmagasinet" url='https://bryllupsliste.cg.no/weddinglist/view/index/id/pg3FXp16oUiVQAbjIARSImFGt52S4i5m/?___store=cgweddingguest' />
            </GiftWrapper>
        </Section>
    </>
    return (
        <FullPage sections={[section1, section2]} />
    );
}

export default Gifts
