import React from 'react'
import styled from 'styled-components'

const Container = styled.div`

    position: relative;
    font-size: 24px;
    width: 100%;
    background-color: blue;
    padding: 5px;
    
    & :nth-child(1) {
        position: absolute;
        bottom: -15px;
        background-color: yellow;
        left: 0;
        right: 0;
        width: 100px;
        margin-left: auto;
        margin-right: auto;
    }
    & :nth-child(2) {
        background-color: green;
    }

    & :nth-child(3) {
        background-color: violet;
    }
`

const Container2 = styled.div`
    font-size: 24px;
    width: 100%;
    background-color: blue;
    padding: 5px;

    display: flex;
    position: relative;
    flex-direction: column;
    
    & :nth-child(1) {
        background-color: yellow;
    }

    & :nth-child(2) {
        position: absolute;
        background-color: green;
        bottom: -15px;

        left: 0;
        right: 0;
        width: 100px;
        margin-left: auto;
        margin-right: auto;
    }

    & :nth-child(3) {
        background-color: violet;
    }
`

const Header = styled.div`
    position: absolute;
    height: 40px;
    width: 100%;
    background-color: green;
`

const FirstPage = styled.div`
    height: 100dvh;
    width: 100%;
    background-color: blue;

`
const SecondPage = styled.div`
    height: 100dvh;
    width: 100%;
    background-color: red;
`

const Content = styled.div` 
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 10px;

    & :nth-child(1) {
        background-color: yellow;
        height: 100%;
        width: 50%;
        border-radius: 10px;

        flex-basis: 50%;
        flex-grow: 1;
        marginright: 10px;
    }

    & :nth-child(2) {
        background-color: yellow;
        height: 100%

        width: 50%;
        flex-basis: 50%;
        flex-grow: 1;
        marginright: 10px;
        border-radius: 10px;

    }

`
const Testing = () => {
    return (
        <>
            <FirstPage>
                <Header>Some important text</Header>
                <Content>
                    <div></div>
                    <div></div>
                </Content>
            </FirstPage>
            <SecondPage>
                <div></div>
                <div></div>

            </SecondPage>
        </>
        // <Container2>
        //     Container
        //     <div>
        //         Child 1
        //     </div>
        //     <div>
        //         Child 2
        //     </div>
        //     <div>
        //         Child 3
        //     </div>
        // </Container2>
    )
}

export default Testing