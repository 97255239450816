import { Entries, Entry, Text } from './Friday'

const Sunday = () => {
    return (
        <Entries>
            <Entry time="13:00">
                <Text>Om det er fint vær og stemning for en luftetur før man vender snuta hjem, tar vi turen til  Geiteberget. Dette er en halvtimes tur, som gir fin utsikt over bygda.</Text>
            </Entry>
        </Entries>
    )
}

export default Sunday