import * as React from 'react';
import Layout from '../components/Layout/Layout';
import HeaderImage from '../components/HeaderImage';
import skarsnuten from '../Images/Misc/skarsnuten.jpg';
import NavBar from '../components/NavBar/NavBar';
import GoogleMaps from '../components/GoogleMaps/GoogleMaps';
import styled from 'styled-components';
import DaySelector from '../components/DaySelector/DaySelector';
import FullPage from '../components/UI/FullPage';


const Section = styled.div`
    background: ${({ theme }) => theme.grey};
    height: 100dvh;
    width: 100%;
    position: relative;
    padding: 80px 50px 50px 50px;
`;

const MapsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export default function Agenda() {
    const section1 = <>

        <NavBar variant='light' />
        <HeaderImage src={skarsnuten} title='Agenda' alt="Informasjonsbilde" />
    </>
    const section2 = <Section>
        <DaySelector />
    </Section>

    return (
        <FullPage sections={[section1, section2]} />
    );
}
