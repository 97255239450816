import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    width: 100%;
    position: relative;

    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    margin-left: auto;
    padding-left: auto;

    @media (max-width: ${({ theme }) => theme.mobile}) {
    }
`

const Time = styled.h2`
    font-family: 'Inter';
    font-size: 24px;
    font-weight: bold;
    width: 30%;
    margin: 10px;

    display: flex;
    align-items: center;

    margin:0;
    justify-content: center;


    @media (max-width: ${({ theme }) => theme.mobile}) {
        width: 100%;
    }

`

export const Text = styled.p`
    font-family: 'Inter';
    font-weight: 200;
    font-size: 24px;
    width: 50%;
    margin: 10px;

    text-align: center;

    @media (max-width: ${({ theme }) => theme.mobile}) {
        margin-bottom: 40px;
        width: 100%;
        font-size: 14px;
    }
`

export const Entries = styled.div`
    display: flex;
    flex-direction: column;
`

export const Divider = styled.div`
    height: 1px;
    width: 300px;
    background-color: black;
`

// Left and right entry of the day
interface EntryProps {
    time: string;
    children: ReactNode;
}
export const Entry = ({ time, children }: EntryProps) => {
    return <Container>
        <Time>
            {time} </Time>
        {children}
    </Container>

}

const Friday = () => {
    return (
        <Entries>
            <Entry time="16-utover">
                <Text>Vi ønsker å invitere de av dere som tenker å komme dagen før, på grilling og mingling på Grøv Søre.
                    Adressen er Lykkjavegen 174c nede ved det røde huset. Dere kan parkere ved loven ovenfor, om det blir
                    fullt der kan man parkere nede ved det røde huset. Det anbefales å reise sammen, da vi blir mange mennesker.
                    Dere trenger ikke å ta med grillmat. Vi stiller med koteletter, tilbehør og pølser. Ta med det dere ønsker å drikke.
                </Text>
            </Entry>
        </Entries>
    )
}

export default Friday