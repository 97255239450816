import { useState, useEffect } from "react";
import styled from "styled-components";

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CountdownBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.primaryLight};
  width: 90px;
  height: 90px;
`;

const CountdownNumber = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.green}

`

const CountdownFont = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.primaryDark};
  font-weight: bold;

`

const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const targetDate = new Date('2024-05-25T14:00:00Z');
        const now = new Date();
        const timeDifference = targetDate.getTime() - now.getTime();

        if (timeDifference <= 0) {
            return {
                days: '00',
                hours: '00',
                minutes: '00',
            };
        }

        const days = String(Math.floor(timeDifference / (1000 * 60 * 60 * 24))).padStart(2, '0');
        const hours = String(Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
        const minutes = String(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');

        return {
            days,
            hours,
            minutes,
        };
    }

    return (
        <CountdownContainer>
            <CountdownBox>
                <CountdownNumber>{timeLeft.days}</CountdownNumber>
                <CountdownFont>Dager</CountdownFont>
            </CountdownBox>
            <CountdownBox>
                <CountdownNumber>{timeLeft.hours}</CountdownNumber>
                <CountdownFont>Timer</CountdownFont>
            </CountdownBox>
            <CountdownBox>
                <CountdownNumber>{timeLeft.minutes}</CountdownNumber>
                <CountdownFont>Minutter</CountdownFont>
            </CountdownBox>
        </CountdownContainer>
    );
};

export default CountdownTimer;