import { useForm, SubmitHandler } from "react-hook-form"
import * as React from 'react';
import Div from '../components/UI/Div';
import styled from 'styled-components';
import Flower from "../Images/Misc/flower.png"
import Stairs from "../Images/Misc/stairs.jpg"
import NavBar from "../components/NavBar/NavBar";
import { db } from "../utils/firebaseConfig";
import { addDoc, collection } from "firebase/firestore";
import toast from "react-hot-toast";

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 70vh;
  width: 60vw;
  position: absolute;
  top: 50%;  /* Move the container down by 50% of viewport height */
  left: 50%; /* Move the container to the right by 50% of viewport width */
  transform: translate(-50%, -50%); /* Center the container */
  border-radius: 10px;
  box-shadow: 0 2px 10px -3px #d3d3d3;
  background-color: #fff;

  max-width: 600px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 90vw;
}
`;


export interface IRSVPProps {

}



type Inputs = {
    name: string;
    email: string;
    allergies: string;
    friday: string;
    message: string;
}

const Input = styled.input`
    border-bottom: 1px solid #e8e8e8;
    width: 100%;
`

const InputContainer = styled.div`
    display: flex;
    width:  100%;
    justify-content: center;
    margin: 10px 0;
`

const Form = styled.form`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 80%;
  flex-direction: column;
`

const Button = styled.button`
    background-color: #fff;
    color: #000;
    padding: 10px 30px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    margin: 10px 0;
    cursor: pointer;
`

const ImageContainer = styled.div`
    width: 75%;
  height: 150px;
  position: absolute;
  bottom: -10%;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally *

`

const FooterImageContainer = styled.div`
    position: fixed;
    bottom: 0px;
    z-index: -1;
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
    -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
`
const FooterImage = styled.img`
    width: 100%;
    object-fit: cover;
    min-height: 80vh;

`
const Title = styled.h1`
    font-size: 2rem;
`
const SubTitle = styled.h2`
    font-size: 1rem;
    margin-bottom: 100px;
`

const TextArea = styled.textarea`
    display: flex;
    width:  100%;
    justify-content: center;
    margin: 10px 0;
    border-bottom: 1px solid #e8e8e8;

`

const RSVPForm = () => {

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>()

    const onSubmit: SubmitHandler<Inputs> = async (data) => {

        try {
            const docRef = await addDoc(collection(db, "rsvp"), {
                data
            }).then((_res) => toast.success("RSVP sendt!"))
        } catch (e) {
            console.error("Error adding document: ", e);
            toast.error("Noe gikk galt, kontakt Carl")
        }
    }


    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Title>Bli med oss</Title>
                <SubTitle>Vennligst registrer innen Februar, 2024. Skriv inn navn på alle som kommer.</SubTitle>

                <InputContainer>
                    <Input placeholder="Alle navn"{...register("name", { required: true })} />
                    {errors.name && <span>This field is required</span>}
                </InputContainer>

                <InputContainer>
                    <Input placeholder="Allergier" {...register("allergies")} />
                </InputContainer>

                <InputContainer>
                    <Input placeholder="Kommer på grilling fredag? (Ja, Nei)" {...register("friday")} />
                </InputContainer>

                <InputContainer>
                    <TextArea placeholder="Melding til oss"  {...register("message")} />
                </InputContainer>

                <Button type="submit">Send</Button>
            </Form>
        </>
    )
}

export default function RSVP(props: IRSVPProps) {



    return (
        <>
            <NavBar variant="dark" />
            <CenteredContainer>
                <RSVPForm />
                {/* <ImageContainer>
                    <img src={Flower} />
                </ImageContainer> */}
            </CenteredContainer>

            <FooterImageContainer>
                <FooterImage src={Stairs} />
            </FooterImageContainer>
        </>
    );
}
