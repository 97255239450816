import React, { useState } from 'react'
import styled from 'styled-components'
import Sunday from './Sunday';
import Friday from './Friday';
import Saturday from './Saturday';

const Container = styled.div`
    width: 100%;
    height: 100%;
`
interface SelectorProps {
    selected?: boolean;
}
const Selector = styled.div<SelectorProps>`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 40px;

`

const SelectedContent = styled.div`
`

const SelectorButton = styled.button<SelectorProps>`
    padding: 10px;
    color: ${({ selected }) => selected ? 'green' : 'black'};
    border-bottom: ${({ selected }) => selected ? '1px solid black' : 'none'};
`

const DaySelector = () => {
    const [selected, setSelected] = useState("fredag")

    const selectorOnClick = (selectedDay: string) => {
        setSelected(selectedDay)
    }

    const isSelected = (selectedDay: string) => {
        return (selectedDay === selected)
    }

    return (
        <Container>
            <Selector>
                <SelectorButton selected={isSelected("fredag")} onClick={() => selectorOnClick("fredag")}>Fredag</SelectorButton>
                <SelectorButton selected={isSelected("lørdag")} onClick={() => selectorOnClick("lørdag")}>Lørdag</SelectorButton>
                <SelectorButton selected={isSelected("søndag")} onClick={() => selectorOnClick("søndag")}>Søndag</SelectorButton>
            </Selector>

            <SelectedContent>
                {
                    selected === "fredag" ?
                        <Friday />
                        :
                        selected === "lørdag" ?
                            <Saturday />
                            :
                            <Sunday />
                }

            </SelectedContent>
        </Container>
    )
}

export default DaySelector