import { useRef, useState } from "react";
import { useOnClickOutside } from "../../hooks";
import Burger from "./Burger";
import Menu from "./Menu";
import styled from "styled-components";

interface NavBarProps {
  variant: "light" | "dark"
}

const NavBar = ({ variant }: NavBarProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const node = useRef<any>(null)

  useOnClickOutside(node, () => setOpen(false))


  return (
    <div ref={node}>
      <Burger open={open} setOpen={setOpen} variant={variant} />
      <Menu open={open} />
    </div>
  )
}

export default NavBar