import React from 'react';
import styled, { keyframes } from 'styled-components';
import border from "../Images/Misc/border.png"


const slidein = keyframes`
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {

      opacity: 100;
      transform: translateX(0%);
    }
`

const FullWidthImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
`;

const FullWidthImageElement = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  filter: brightness(50%);

  object-fit: cover;
`;

export const CenteredContent = styled.div`
  font-family: "Alex Brush", cursive;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;

  padding: 40px;


`;

export const MainText = styled.h1`
  font-size: 7em;
  margin: 0;
  animation: ${slidein} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);


  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 5em;
    }
`;

export const SubText = styled.p`
  font-size: 3em;
  margin: 0;
  animation: ${slidein} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-delay: 0.2s;


  @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1em;
    }
`;

const BorderImageTop = styled.img`
    width: 200px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    animation: ${slidein} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);

`;

const BorderImageBottom = styled.img`
    width: 200px;
    position: absolute;
    z-index: 1;
    bottom: 0;

    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    animation: ${slidein} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-delay: 0.3s;
`;



interface IHeaderImageProps {
  title: string,
  subtitle?: string
  src: string
  alt: string
}



const HeaderImage = ({ title, subtitle, src, alt }: IHeaderImageProps) => {
  return (
    <FullWidthImageWrapper>

      <FullWidthImageElement
        src={src}
        alt={alt}
      />


      <CenteredContent>
        <BorderImageTop src={border} alt="border" />
        <MainText>{title}</MainText>
        {subtitle ? <SubText>25.05.2024</SubText> : null}
        <BorderImageBottom src={border} alt="border" />
      </CenteredContent>


    </FullWidthImageWrapper>
  );
};

export default HeaderImage;
