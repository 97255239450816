import React from 'react'
import { Entries, Entry, Text } from './Friday'

const Saturday = () => {
    return (
        <Entries>
            <Entry time="14:00">
                <Text>Vielse i Hemsedal kirke, møt opp i god tid.
                    Etter vielsen går det noen timer hvor brud og brudgom skal ta bilder, da er det opp til deg om du vil dra rett til Skarsnuten hotell og mingle, eller ta en liten pause på hotellrommet.
                </Text>
            </Entry>

            <Entry time="17-18">
                <Text>Bilder utenfor hotellet.
                </Text>
            </Entry>

            <Entry time="18-utover">
                <Text>Vi setter oss til bords, håper du finner deg godt til rette med naboene dine, for her skal vi sitte en stund.
                    Vi vil få servert 3-retters middag, og det vil bli taler underveis.

                    Deretter blir det kaker og dans så lenge man ønsker ut i nattetimene.
                </Text>
            </Entry>
        </Entries>
    )
}

export default Saturday