import styled from "styled-components";
import GoogleMaps from "../components/GoogleMaps/GoogleMaps";
import HeaderImage from "../components/HeaderImage";
import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";
import kirke from "../Images/Misc/kirke.jpg"
import FullPage from "../components/UI/FullPage";

const Section = styled.div`
    background: ${({ theme }) => theme.grey};
    height: 100dvh;
    width: 100%;
    position: relative;
    padding: 80px 50px 50px 50px;

    @media (max-width: 1200px) {
        padding: 20px;
    }
`;

const MapsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;


    @media (max-width: 1200px) {
      height: 40%;
    }
`;

const Title = styled.h1`
    font-size: 20px;

`

const Paragraphs = styled.div`
    display: flex;
    flex-direction: row;
    
    font-family: 'Inter';
    font-weight: 200;
    font-size: 18px;

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;

    }
`

const Link = styled.a`
    color: green;
    font-weight: bold;

    &:hover {
        cursor: pointer;
    }

`
const ParagraphContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 80%;

    @media (max-width: 1200px) {
        width: 100%;
    }

`

const Paragraph = styled.p`
    margin: 20px;
    width: 500px;

    @media (max-width: 1200px) {
        width: 800px;
    }
`

const Information = () => {
    const section1 = <>
        <NavBar variant='light' />
        <HeaderImage src={kirke} title='Informasjon' alt="Informasjonsbilde" />
    </>
    const section2 = <>
        <Section>
            <Paragraphs>
                <ParagraphContainer>
                    <Paragraph>
                        Kleskoden i bryllupet vil være formelt. Noen kommer til å benytte bunad under vielsen, og deretter skifte til kjole e.l til middagen.

                    </Paragraph>
                </ParagraphContainer>

                <ParagraphContainer>
                    <Paragraph>Bryllupet vil bli holdt i Hemsedal, lørdag 25.05.2024. Mer informasjon rundt agendaen finner dere <Link href="/agenda">her</Link>.

                        Vi anbefaler alle gjestene våre å booke rom på Skarsnuten Hotell fra fredagen til og med søndagen. Dere kan oppgi at dere skal i bryllupet for å få en ekstra god pris.</Paragraph>
                </ParagraphContainer>
                <ParagraphContainer>
                    <Paragraph>For å booke online kan dere gjøre følgende: gå inn på <Link href="http://online.bookvisit.com/v2/Campaign/CampaignDetails?channelId=3dc9f882-eaec-4a34-a80c-6bb489e04595&campaignId=2fae2b73-bd25-4e68-892c-e22cbd526593">denne linken</Link>.
                        Skriv inn koden: carlogmailinn for å få registrert dere samt rabatt. Dersom dere får problemer med dette kan dere ta kontakt med Skarsnuten hotell.</Paragraph>
                </ParagraphContainer>

            </Paragraphs>

        </Section>
    </>
    return (
        <FullPage sections={[section1, section2]} />
    );
}

export default Information
