import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { styled, ThemeProvider } from 'styled-components';
import './App.css';
import Header from './components/Header';
import HeaderImage from './components/HeaderImage';
import Home from './Pages/Home';
import OurStory from './Pages/OurStory';
import Photos from './Pages/Photos';
import { theme } from './theme';
import NavBar from './components/NavBar/NavBar';
import RSVP from './Pages/RSVP';
import Agenda from './Pages/Agenda';
import Testing from './Testing';
import Gifts from './Pages/Gifts';
import Information from './Pages/Information';
import { Toaster } from 'react-hot-toast';

const Div = styled.div``

function App() {
  return (

    <ThemeProvider theme={theme}>
      <Router>
        {/* <HeaderImage /> */}
        <Toaster position='bottom-center' reverseOrder={false} />
        <Routes>
          <Route path="/" Component={Home} />

          <Route path="/agenda" Component={Agenda} />
          <Route path="/our-story" Component={OurStory} />
          <Route path="/photos" Component={Photos} />
          <Route path="/rsvp" Component={RSVP} />
          <Route path="/gaver" Component={Gifts} />
          <Route path="/info" Component={Information} />


          <Route path="/testing" Component={Testing} />

        </Routes>
      </Router>
    </ThemeProvider>

  );
}

export default App;
