import { styled } from "styled-components"
import HeaderImage, { CenteredContent, MainText } from "../components/HeaderImage"
import NavBar from "../components/NavBar/NavBar"
import { FaBars } from 'react-icons/fa';
import Layout from "../components/Layout/Layout"
import waterfall from "../Images/Couple/waterfall.jpg"
import CountdownTimer from "../components/CountdownTimer/CountdownTimer";
import ReactFullpage from "@fullpage/react-fullpage";
import FullPage from "../components/UI/FullPage";


const Container = styled.div`
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-start; /* Align to the top */
  align-items: center;
  height: 100%;
`;

interface ToggleProps {
  handleNavToggle: React.MouseEventHandler<HTMLButtonElement>
}

const Toggle = ({ handleNavToggle }: ToggleProps) => {
  return (
    <StyledToggle className="animate__animated animate__fadeInRight" onClick={handleNavToggle}>
      <FaBars />
    </StyledToggle>
  )
}

const StyledToggle = styled.button`
    position: fixed;
    top: 5%;
    right: 4%;
    color: #222;
    background: #eee;
    padding: .75rem;
    display: flex;
    place-items: center;
    font-size: 2rem;
    cursor: pointer;
`;


const CountdownWrapper = styled.div`
  background: ${({ theme }) => theme.grey};
  height: 100dvh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

const WeddingDate = styled.div`
  font-family: 'Alex Brush';
  display: flex;
  justify-content: center;
  font-size: 80px;
  text-align: center;
  margin: 40px 40px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 60px;
    margin: 0px 20px;
  }
`

const WeddingLocationContainer = styled.div`
  display: flex;
  font-size: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 20px;
  }
`

const WeddingLocation = styled.div`
  font-family: 'Poppins';
`

const ImgWrapper = styled.div`
  position: relative
  height: 100%;
`

const ImgContainer = styled.div`
  position: absolute; 
  bottom: 0;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 500px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 400px;
    }
`

const Img = styled.img`
`

const Centered = styled.div`

`

const Home = () => {
  const section1 = <>

    <NavBar variant="light" />
    <HeaderImage title="Mailinn & Carl" subtitle="25.05.24" src={waterfall} alt="hovedbilde" />
  </>

  const section2 = <>
    <CountdownWrapper>
      <Centered>
        <WeddingDate>25. mai 2024</WeddingDate>
        <WeddingLocationContainer>
          <WeddingLocation>SKARSNUTEN HOTELL</WeddingLocation>
          <WeddingLocation>3560 HEMSEDAL</WeddingLocation>
        </WeddingLocationContainer>
        <CountdownTimer />
      </Centered>
    </CountdownWrapper>

  </>
  return (
    <FullPage sections={[section1, section2]} />
  )
}
export default Home
