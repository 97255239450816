import ReactFullpage from '@fullpage/react-fullpage';
import React from 'react'

interface FullPageProps {
    sections: Array<React.ReactNode>;
}
const FullPage = ({ sections }: FullPageProps) => (
    <ReactFullpage
        licenseKey={'lmsPDTW?w2'}
        scrollingSpeed={1000}
        credits={{}}

        render={({ state, fullpageApi }) => {
            return (
                <ReactFullpage.Wrapper>
                    {sections.map((section, index) => {
                        return (
                            <div className="section" key={`${index}`}>
                                {section}
                            </div>)
                    })}

                </ReactFullpage.Wrapper>
            );
        }}
    />
)

export default FullPage